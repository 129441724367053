import React from 'react'
import JamsatckLogo from "../../../static/images/commonImg/jamstack-logo-text.svg"
import JamWpLogo from "../../../static/images/commonImg/jam-wp-logo.svg"
import WPLogo from "../../../static/images/commonImg/wp-logo-text.svg"
import HeadlessLogos from "../../../static/images/commonImg/headlessCMS-logos.svg"
import ShopifyLogo from "../../../static/images/commonImg/shopify-logo-text.svg"
import ReactLogo from "../../../static/images/commonImg/react-logo-black.svg"
import GatsbyLogo from "../../../static/images/commonImg/gatsby-logo-text.svg"
import NextjsLogo from "../../../static/images/commonImg/new-nextjs-logo.svg"
import NetlifyLogo from "../../../static/images/commonImg/netlify-logo.svg"
import NormalSVG1 from "../../../static/images/singleBlogPageImages/heroImg/hero-svg-1.svg"
import NormalSVG2 from "../../../static/images/singleBlogPageImages/heroImg/hero-svg-2.svg"
import NormalSVG3 from "../../../static/images/singleBlogPageImages/heroImg/hero-svg-3.svg"
import NormalSVG4 from "../../../static/images/singleBlogPageImages/heroImg/hero-svg-4.svg"
import NormalSVG5 from "../../../static/images/singleBlogPageImages/heroImg/hero-svg-5.svg"

const contents = [
    {type: "normal1", img: NormalSVG1},
    {type: "normal2", img: NormalSVG2},
    {type: "normal3", img: NormalSVG3},
    {type: "normal4", img: NormalSVG4},
    {type: "normal5", img: NormalSVG5},
    {type: "jamstack", img: JamsatckLogo},
    {type: "wordpress", img: WPLogo},
    {type: "jam-wp", img: JamWpLogo },
    {type: "headless", img: HeadlessLogos},
    {type: "shopify", img: ShopifyLogo},
    {type: "react", img: ReactLogo},
    {type: "gatsby", img: GatsbyLogo},
    {type: "nextjs", img: NextjsLogo},
    {type: "netlify", img: NetlifyLogo},
]

const HeroSvg = ({ heroImg }) => {
    const selectedContent = contents.find(content => content.type === heroImg)

    return <img src={selectedContent.img} alt="blog-hero-img" className="pageheader-svg"/>
}

export default HeroSvg