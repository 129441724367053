import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SvgImgSelector from "../svgImgSelector"
import { blogBGColor } from "../../utils/variables"

const RelatedPosts =({ postData, id })=>(
    <StaticQuery
        query={graphql`
              query RelatedPosts {
                  allMarkdownRemark(
                      sort: { order: DESC, fields: [frontmatter___id] }
                  ){ 
                      edges{  
                        node{
                          id  
                          frontmatter{
                                path
                                title
                                fakeData
                                date
                                tags
                                featuredImage {
                                    childImageSharp {
                                      gatsbyImageData(placeholder: BLURRED, quality: 10)
                                    }
                                }
                            }     
                        } 
                      } 
                    }
                }
        `}
        render={data => {
            const relatedPosts = data.allMarkdownRemark.edges.filter(edge => 
                {
                    if(edge.node.frontmatter.title === postData.title){
                        return false
                    }
                    for(let i = 0; i < edge.node.frontmatter.tags.length; i++){
                        if(edge.node.frontmatter.tags[0] === ( postData.tags[0] === "Shopify" ? "スピードUP" : postData.tags[0] )){  // If the article is about Shopify, return "スピードUP" article 08/Apri/2021
                            return true
                        }else{
                            return false
                        }
                    }
                    return null
                }
            )
            if (!relatedPosts) { return  null }
            return(
                <div className="related-posts">
                      <h2>関連記事</h2> 
                      {3 <= relatedPosts.length ? 
                        <>
                        <div className="grid-container">
                            {relatedPosts.slice(0, 3).map((x, index)=>                            
                                <div className="grid-item" key={index}>
                                    <Link to={`${x.node.frontmatter.path + "/?r=" + id}`}>      
                                        <div className="img-container">
                                            <GatsbyImage image={x.node.frontmatter.featuredImage.childImageSharp.gatsbyImageData} alt="xxxx"/>
                                        </div>
                                        {/* <div className="text-container"> 
                                            <h3>{x.node.frontmatter.title}</h3>
                                            <p>【 { x.node.frontmatter.tags[0] } 】<span>{x.node.frontmatter.fakeData ? x.node.frontmatter.fakeData : x.node.frontmatter.date} </span></p>
                                        </div>  */}
                                    </Link> 
                                </div>
                            )}
                        </div>
                        </>
                      :
                        <>
                        <div className="grid-container">
                            {data.allMarkdownRemark.edges.slice(0, 6).map((x, index) =>                             
                                <div className="grid-item" key={index} style={{backgroundColor: blogBGColor[index.toString().slice(-1).replace("9", "0")]}}>
                                    <Link to={`${x.node.frontmatter.path + "/?r=" + id}`}>      
                                        <div className="img-container">
                                            <SvgImgSelector index={index}/>
                                        </div>
                                        <div className="text-container"> 
                                            <h3>{x.node.frontmatter.title}</h3>
                                            <p>【 { x.node.frontmatter.tags[0] } 】<span>{x.node.frontmatter.fakeData ? x.node.frontmatter.fakeData : x.node.frontmatter.date} </span></p>
                                        </div> 
                                    </Link> 
                                </div>
                            )}
                        </div>
                        </>  
                      } 
                </div>
            )}
        }
      />
)

export default RelatedPosts