import React, { useState, useEffect, useRef } from 'react'
import { useScrollPosition } from "../../hooks/useScrollPosition"

const ProgressBar = ({ children, setScrollPercentage }) => {
    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    }, [])

    const scrollPosition = useScrollPosition()
    
    const percentage = (1 - ((height - scrollPosition) / height)) * 100
    const adjustPercentage = 100 < percentage ? 100 : percentage
    
    // Without useEffect, error message appears
    useEffect(() => {
        setScrollPercentage(percentage)
    }, [percentage, setScrollPercentage])

    return (
        <div ref={ref}>
            <div className="progress-bar-container">
                <div style={{width: `${adjustPercentage}%`}}></div>
            </div>
            {children}
        </div>
    )
}

export default ProgressBar