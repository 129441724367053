import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import Logo from "../../../static/images/logo/logo-blue-1.png"

const StructuredData = ({ title, description, featuredImage, date, update }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  
  const {
    defaultTitle,
    //defaultDescription,
    siteUrl,
    //defaultKeywords,
    defaultImage,
  } = site.siteMetadata

  const jsonLdStructuredData = {
      "@context": "https://schema.org/", 
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": siteUrl + "/blog",
      },
      author: {
        "@type": "Person",
        name: defaultTitle,
        url:  siteUrl,
        image: Logo,
      },
      publisher: {
        "@type": "Organization",
        name: defaultTitle,
        url: siteUrl,
        logo: {
            "@type": "ImageObject",
            url: Logo,
            width: 300,
            height: 300
        }
      },
      headline: title,
      image: featuredImage ? featuredImage : defaultImage,
      url: `${siteUrl}${pathname}`,
      description: description,
      datePublished: date,
      dateCreated: date,
      dateModified: update ? update : null,
/*       sameAs: [
        "https://www.facebook.com/put-your-user-id-here",
        "https://stackoverflow.com/put-your-user-id-here",
        "https://github.com/put-your-user-id-here",
        "https://www.instagram.com/put-your-user-id-here",
        "https://twitter.com/put-your-user-id-here",
        "https://www.linkedin.com/put-your-user-id-here",
      ], */
  }

  return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(jsonLdStructuredData)}</script>
      </Helmet>
  )
}

export default StructuredData

const query = graphql`
  query SturucturedDataQuery {
    site {
      siteMetadata {
          defaultTitle
          defaultDescription
          siteUrl
          defaultKeywords
          defaultImage
      }
    }
  }
`