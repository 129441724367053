import React from 'react'
import { Link } from "gatsby"

/* const isBrowser = () => typeof window !== "undefined" //To check if "window" exists. Otherwise build fails.

const RenderComponent = ({ path }) => {
    return(
        isBrowser() && window.location.pathname.includes("/blog/") ? <Notification path={path} /> : null
    )
} */

const Notification = ({ path }) => {
    return (
        <div className="notification-root">
            <div className="inside-container">
                Reactを『なんとなく』は書ける人が、2日で自信を持って使えるようになる新刊書リリース【React初級〜中級者向け】
                <a href="https://monotein.base.shop/p/00001" rel="noopener noreferrer" target="_blank" >くわしくはコチラ</a>
            </div>
        </div>
    )
}

export default Notification

//export default RenderComponent