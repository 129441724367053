import React from 'react'
import AlignLeftIcon from "../../../static/images/singleBlogPageImages/align-left.svg"

const BlogTableOfContent = ({ headings }) => {
    const RemoveSpecialChar= (x) => {
        return ( 
            `#${x.value.replace(/\s+/g, "-").replace(/\.+/g, "").replace("「", "").replace("」", "").replace("『", "").replace("』", "").replace("【", "").replace("】", "").replace("（", "").replace("）", "").replace("？", "").replace("＝", "").replace("％", "").replace("＆", "").replace("　", "").replace("・", "").toLowerCase()}`
        )
    }
    return (
        <div className="blogTableOfContent">
            <div className="img-text-container">
                <img src={AlignLeftIcon} alt="align-left-icon"/>
                <h3>目次</h3>
            </div>
            {headings.map((x, index) => 
                <ul key={index}>
                    <li>{x.depth === 2 && <h3>• <a href={RemoveSpecialChar(x)}>{x.value}</a></h3>}</li>
                    <li>{x.depth === 3 && <h4>― <a href={RemoveSpecialChar(x)}>{x.value}</a></h4>}</li>
                </ul>
            )}
        </div>
    )
}

export default BlogTableOfContent