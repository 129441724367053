import React from 'react'
import { Link } from 'gatsby'
import ArrowLeft from "../../../static/images/svgImportedAsComponent/arrow-left.svg"
import ArrowRight from "../../../static/images/svgImportedAsComponent/arrow-right.svg"

const PrevNext =({ pageContext, id }) => {
    const { previous, next } = pageContext
    return (
        <div className="prev-next">
            {next && 
                <Link to={`${next.frontmatter.path + "/?r=" + id}`} className="prevnext-card">
                    <div className="text-img-container">
                        <ArrowLeft />
                        <h3> {next.frontmatter.title}</h3>
                    </div>
                </Link>
            }
            {previous && 
                <Link to={`${previous.frontmatter.path + "/?r=" + id}`} className="prevnext-card">
                    <div className="text-img-container">
                        <h3>{previous.frontmatter.title}</h3>
                        <ArrowRight />
                    </div>
                </Link>
            }
        </div>
    )
}

export default PrevNext