import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
//import { useWindowDimension } from "../../hooks/useWindowDimension"
import Layout from "../layout"
import Seo from "../seo"
import StructuredData from "./structuredData"
import ProgressBar from "./progressBar"
import BlogTableOfContent from "./blogTableOfContent"
import HeroSvg from "./heroSvg"
import PrevNext from "./prevNext"
import RelatedPosts from "./relatedPosts"
//import BottomCTA from "./bottomCTA"
import { Writer, FreeEnqueryForm /* , EnqueryPart */ } from "./writer"
import PenIcon from "../../../static/images/singleBlogPageImages/pen.svg"
import RewriteIcon from "../../../static/images/singleBlogPageImages/rewrite.svg"

import Notification from '../notification'
import RegisterNewsLetterBody from "../registerNewsLetter"

const SinglePost =({ data, pageContext }) => {
    const shortenData = data.markdownRemark
    const { id, path, date, update, title, excerpt, description, keywords, heroImg, estimatedTime, ad, featuredImage } = shortenData.frontmatter

    const [scrollPercentage, setScrollPercentage] = useState(0)

    //const { width } = useWindowDimension()

    const [entryTime, setEntryTime] = useState("")
    useEffect(() => {
        setEntryTime(new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }).slice(0, 17),)
    }, [])

    // Access Checker
    useEffect(() => {
        window.location.hostname !== "localhost" &&　window.location.hostname !== "mirror-monotein.netlify.app" && path.includes("/blog/looking-for-sales-persons") &&
            fetch('https://us-central1-nov182021.cloudfunctions.net/server/access-check', {
                method: 'POST',
                headers: {  'Accept': 'application/json', 'Content-Type': 'application/json'},
                body:  JSON.stringify({ collectionName: "single-blog", name: window.location.search, email: "営業募集noticeをクリックした人" })
            })
            .then(res => res.json())
            .then(data => console.log("Access Checker success", data))
            .catch(err => console.log("Access Checker fail", err))
    }, [path])

    if(shortenData == null || undefined) return <div>Build Fail</div>
    return (
        <Layout>
        <Notification path={path}/>
        <Seo title={title} 
             description={excerpt + `${"―"}` + description}
             keywords={keywords}  
             featuredImage={featuredImage.childImageSharp.original.src} 
        />
        <StructuredData title={title} 
                        description={description}
                        featuredImage={featuredImage.childImageSharp.original.src} 
                        date={date} 
                        update={update}
        />
      {/*   {ad !== "none" &&
            <BlogAD ad={ad} 
                    path={path} 
                    scrollPercentage={scrollPercentage}
                    entryTime={entryTime}
            />
        } */}
            <ProgressBar setScrollPercentage={setScrollPercentage}>
            <div className="single-post">
                <div className="single-post-page-header">
                    <div className="container">
                        <div className="title-excerpt-container"/*  style={{marginTop: "80px"}} */> {/* due to download　notice */}
                            <HeroSvg heroImg={heroImg}/>
                            <h1>{title}</h1>
                            {/* <summary>{excerpt}</summary> */}
                        </div>
                        <p className="icons-container">
                            <img src={PenIcon} alt="pen-icon"/> 
                            {shortenData.frontmatter.fakeData ? shortenData.frontmatter.fakeData : date}
                            {update !== "none" && <><img src={RewriteIcon} alt="rewrite-icon"/>{update} </>}
                        </p>
                        <p style={{margin: "20px 0", fontSize: "85%"}}>この記事は約<span style={{color: "#408CFF", fontSize: "125%", margin: "0 3px"}}>{estimatedTime}</span>分で読めます</p>
                    </div>
                </div>
                
                <div className="container">
                    <div className="single-post-container">
                        {!path.includes("/blog/looking-for-sales-persons") && 
                            <>
                                <BlogTableOfContent headings={shortenData.headings}/>
                                <Writer path={path} />
                            </>
                        }
                        <div className="main">    
                            <div className="htmltext" dangerouslySetInnerHTML={{__html: shortenData.html}}/>                       
                            {/* {width < 960 && 98 > scrollPercentage && <BottomCTA path={path} title={title}/>}  */} 
                           {/*  {path.includes("/blog/looking-for-sales-persons") && <FreeEnqueryForm path={path}/>} */}
                        </div>
                        <RegisterNewsLetterBody 
                                fromWhere={path}
                                backendUrl="https://us-central1-nov182021.cloudfunctions.net/server/news-letter"
                                text={<p>React、Next.js、TypeScriptなど最新のウェブ開発のお役立ち情報を、ビギナー向けにかみ砕いて無料配信中。<br/>（*配信はいつでも停止できます）</p>}
                        />
                        {!path.includes("/blog/looking-for-sales-persons") && 
                            <>
                                <PrevNext pageContext={pageContext}  id={id} />
                                <RelatedPosts postData={shortenData.frontmatter} id={id} />
                            </>
                        }
                    </div>
                </div>
            </div>
            </ProgressBar>
        </Layout>
    )
}

export const singlePost = graphql`
    query SinglePostQuery ($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                id
                path
                fakeData
                date
                update
                title
                author
                excerpt
                description
                keywords
                tags
                categoryTagEN
                heroImg
                ad
                estimatedTime
                show
                featuredImage {
                    childImageSharp {
                        original {
                          src
                        }
                    }
                }
            }
            headings {
                depth
                value
            }
        }
    }
` 

export default SinglePost